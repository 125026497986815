<template>
    <zw-sidebar @shown="shown"
                :title="$t('common.form.title.customer_templates')"
    >
        <ValidationObserver tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row>
                    <b-col cols="12">
                        <zw-input-group v-model="form.name"
                                        name="name"
                                        :label-prefix="labelPrefix"
                                        :validate="{required:true}"
                        ></zw-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-badge v-for="(placeholder, index) in getEmailPlaceholders()"
                                 v-bind:key="index"
                                 variant="info"
                                 role="button"
                                 class="mr-2"
                                 :title="$t(placeholderPrefix+placeholder)"
                                 @click="insertPlaceholder(placeholder)"
                        >{{ placeholder }}
                        </b-badge>
                    </b-col>
                    <b-col cols="12">
                        <zw-textarea-group v-model="form.template"
                                           name="template"
                                           ref="emailtextarea"
                                           :label-prefix="labelPrefix"
                                           disable-label
                        />
                    </b-col>
                </b-row>
                <b-row align-h="end">
                    <b-col sm="12" class="text-sm-right text-xs-center">
                        <b-button block @click="onSubmit" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </ValidationObserver>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import ValidateMixin from "@/mixins/validate";
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'customer-template-modal',
    mixins: [ValidateMixin, commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            defaultForm: {},
            form: {},
            labelPrefix: 'customer.templates.label.',
            placeholderPrefix: 'settings.email_template.placeholder.',
        }
    },
    methods: {
        ...mapGetters('Customer', ['getTemplate']),
        ...mapGetters('EmailTemplate', ['getEmailPlaceholders']),
        shown() {
            const template = this.$store.dispatch('EmailTemplate/fetchTemplate', 0)
            this.$store.dispatch('Customer/fetchCustomerTemplate', this.payload.id)
                .then((data) => {
                    this.form = {
                        ...JSON.parse(JSON.stringify(this.defaultForm)),
                        ...{id: this.payload.id},
                        ...JSON.parse(JSON.stringify(this.getTemplate()))
                    }
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('Customer/saveTemplate', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        }).finally(() => {
                        this.loading = false
                    })
                } else {
                    this.showValidationError()
                }
            })
        },
        insertPlaceholder(placeholder) {
            const textarea = this.$refs.emailtextarea.$refs.textarea;

            let startPos = textarea.selectionStart
            let endPos = textarea.selectionEnd
            this.form.template = textarea.value.substring(0, startPos) + '{{' + placeholder + '}}' + textarea.value.substring(endPos, textarea.value.length);
        },
    },
}
</script>